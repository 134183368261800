<script>
    import localizer from "@/common/i18n.js";
    import FormInput from "@/components/FormInput.svelte";
    import {capitalize} from "../common/utils.js";

    export let label;
    export let day;
    export let month;
    export let year;

    const t = localizer({
        nb: {
            'choose': 'Velg',
            'date': 'dag',
            'month': 'måned',
            'year': 'år',
        },
        nn: {
            'choose': 'Velg',
            'date': 'dag',
            'month': 'månad',
            'year': 'år',
        }
    });
</script>

<FormInput name="birth_date">
    <div class="birth_date" data-name="birth_date">
        <fieldset>
            <legend>{label}</legend>
            <div class="birth_date--wrapper">
                <div class="select-wrapper">
                    <select name="birth_date" aria-label="{t('choose')} {t('date')}"  autocomplete="bday-day" required="true">
                        <option selected="selected" disabled="true" hidden="true">{capitalize(t('date'))}</option>
                        {#each Array.from(Array(31+1).keys()).slice(1) as i}
                            <option value={i} selected={i === day ? "selected" : ""}>{i}</option>
                        {/each}
                    </select>
                </div>
                <div class="select-wrapper">
                    <select name="birth_month" aria-label="{t('choose')} {t('month')}" autocomplete="bday-month" required="true">
                        <option selected="selected" disabled="true" hidden="true">{capitalize(t('month'))}</option>
                        {#each Array.from(Array(12+1).keys()).slice(1) as i}
                            <option value={i} selected={i === month ? "selected" : ""}>{i}</option>
                        {/each}
                    </select>
                </div>
                <div class="select-wrapper">
                    <select name="birth_year" aria-label="{t('choose')} {t('year')}" autocomplete="bday-year" required="true">
                        <option selected="selected" disabled="true" hidden="true">{capitalize(t('year'))}</option>
                        {#each Array.from(Array(new Date().getFullYear() + 1).keys()).slice(1900).reverse() as i}
                            <option value={i} selected={i === year ? "selected" : ""}>{i}</option>
                        {/each}
                    </select>
                </div>
            </div>
        </fieldset>
    </div>
</FormInput>

<style>
@import '../common/colors.css';

.birth_date {
    box-sizing: border-box;
    display: block;
    position: relative;
    width: 100%;
    flex-grow: 1;
    width: 100%;
}

.birth_date .select-wrapper {
    position: relative;
    flex-grow: 1;
    margin-left: 10px;
}
.birth_date .select-wrapper:first-child {
    margin-left: 0;
}
  
.birth_date .select-wrapper::after {
    border-top: 8px solid var(--darkGray);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    content: " ";
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -4px;
    font-size: 10px;
    pointer-events: none;
    height: 0;
    width: 0;
}
  
.birth_date select {
    border: 2px solid var(--fieldGray);
    border-radius: 0;
    appearance: none;
    background-color: transparent;
    padding: 0 30px 0 10px;
    font-family: inherit;
    line-height: 46px;
    height: 46px;
    font-size: 17px;
    outline: 0;
    width: 100%;
    box-sizing: border-box;
}

.birth_date select:focus {
    border: 2px solid var(--black);
}

.birth_date select::-ms-expand {
    display: none;
}

.birth_date fieldset {
    box-sizing: border-box;
    border: 0 none;
    padding: 0;
    position: relative;
    z-index: 2;
    margin: 0;
}

.birth_date .birth_date--wrapper {
    display:flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
  
.birth_date legend {
    box-sizing: border-box;
    color: var(--black);
    display: block;
    max-width: 100%;
    padding: 0;
    white-space: normal;
    margin: 0;
    background: var(--white);
    padding: 0;
    margin-bottom: .5em;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
}

</style>